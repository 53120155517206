<template>
  <div class="text-black flex divide-x">
    <div class="w-300px bg-slate-100 pa-5 flex flex-col">
      <div class="font-semibold text-4">What is Orie</div>
      <p class="mt-4">
        Orie is a complete business and administration platform for
        entrepreneurs and community leaders.
      </p>
      <div class="mt-4 flex flex-col gap-2">
        <VArrowBtn :to="{ name: 'sell' }" class="fit-content" size="small">
          Learn more
        </VArrowBtn>
        <VArrowBtn
          :to="{ name: 'sell-contact' }"
          variant="text"
          class="fit-content"
          size="small"
        >
          Contact for details
        </VArrowBtn>
      </div>
    </div>
    <div class="w-400px">
      <div class="py-5">
        <v-list lines="three" class="py-0!">
          <v-list-item
            v-for="(service, i) in services"
            :key="i"
            :title="service.text"
            :subtitle="service.subtitle"
            :to="service.to"
            density="compact"
            class="pt-1! pb-2! px-5!"
          >
            <template #prepend>
              <div
                class="w-50px h-50px rounded-lg border border-1 center-content mr-2 mt-2"
              >
                <div
                  class="w-40px h-40px rounded-md bg-gray-200 center-content text-gray-600"
                >
                  <Icon v-if="service.icon" :name="service.icon" size="25" />
                </div>
              </div>
            </template>
          </v-list-item>
        </v-list>
      </div>

      <div class="px-5">
        <v-divider />
      </div>

      <div class="pa-5">
        <VArrowBtn :to="{ name: 'sell-pricing' }" variant="text" size="small">
          Plans
        </VArrowBtn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { defineMenuItems } from "../lib";

export default defineComponent({
  setup() {
    const services = defineMenuItems([
      {
        text: "Commerce",
        icon: "gala:bag",
        subtitle:
          "Sell online, in person, and around the world. Get your products in front of customers who care.",
        to: { name: "sell-commerce" },
      },
      {
        text: "Appointments",
        icon: "gala:clock",
        subtitle:
          "Online booking system. Let clients schedule appointments, get reminders and secure payments online.",
        to: { name: "sell-appointments" },
      },
      {
        text: "Events",
        icon: "gala:calendar",
        subtitle:
          "Create and manage events. Sell tickets and collect payments online.",
        to: { name: "sell-events" },
      },
    ]);

    return {
      services,
    };
  },
});
</script>
