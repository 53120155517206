import PlatformMenu from "./desktop-menus/PlatformMenu.vue";
import ResourceMenu from "./desktop-menus/ResourceMenu.vue";

export type MenuItem = {
  text: string;
  subtitle?: string;
  to?: any;
  href?: string;
  target?: string;
  items?: MenuItem[];
  component?: any;
  icon?: string;
};

export const defineMenuItems = (items: MenuItem[]): MenuItem[] => items;

export const initDesktopMenus = (element: Element) => {
  const expandMenu = element.querySelector(".header__expandMenu")!;
  const menus = element.querySelectorAll(".menu__container > *")!;
  const navLinks = element.querySelectorAll(".nav--link")!;
  const indicator = element.querySelector(".tip")!;
  let isMouseOnMenu = false;
  let currentNav: Element | null;
  let navsVisited = 0;

  const handleMouseEnter = (e: Event) => {
    // @ts-expect-error object exists
    if (!e.target.dataset.expand) {
      forceInitialState();
      return;
    }

    navsVisited += 1;

    if (navsVisited === 1) {
      expandMenu.classList.add("new--expand");
      menus.forEach((menu) => menu.classList.add("first"));
      indicator.classList.add("first");
    } else {
      expandMenu.classList.remove("new--expand");
      menus.forEach((menu) => menu.classList.remove("first"));
      indicator.classList.remove("first");
    }

    navLinks.forEach((navLink) => {
      if (navLink === e.target) {
        navLink.classList.add("hover");
        currentNav = navLink;
      } else {
        navLink.classList.remove("hover");
      }
    });

    const navLinkCenter = Math.floor(
      // @ts-expect-error type is known
      e.target.offsetLeft + e.target.clientWidth / 2
    );

    // @ts-expect-error style exists
    indicator.style.transform = `translateX(${navLinkCenter}px)`;
    // @ts-expect-error style exists
    indicator.style.opacity = "1";

    // @ts-expect-error target exists
    const targetMenu = document.querySelector(`#${e.target.dataset.expand}`)!;
    const targetCoords = targetMenu.getBoundingClientRect();
    const { width: targetWidth, height: targetHeight } = targetCoords;

    // @ts-expect-error style exists
    expandMenu.style.width = targetWidth + "px";
    // @ts-expect-error style exists
    expandMenu.style.height = targetHeight + "px";

    const prevMenu = targetMenu.previousElementSibling;

    targetMenu.classList.remove("prev");

    if (prevMenu) {
      prevMenu.classList.add("prev");
    }

    menus.forEach((menu) => {
      if (menu.id === targetMenu.id) {
        menu.classList.add("active");
      } else {
        menu.classList.remove("active");
      }
    });

    expandMenu.classList.add("expand");
  };

  const handleMouseLeave = (e: Event) => {
    // @ts-expect-error e.y exists
    if (isMouseOnMenu || e.y > 50) {
      return;
    }

    forceInitialState();
  };

  const forceInitialState = () => {
    expandMenu.classList.remove("expand", "active");
    currentNav?.classList.remove("hover");
    menus.forEach((menu) => menu.removeAttribute("class"));
    indicator.classList.remove("first");
    // @ts-expect-error style exists
    indicator.style.opacity = "0";
    currentNav = null;
    navsVisited = 0;
  };

  // const { height: menuHeight, width: menuWidth } =
  //   expandMenu.getBoundingClientRect();

  navLinks.forEach((navLink) => {
    navLink.addEventListener("mouseenter", handleMouseEnter);
  });

  expandMenu.addEventListener("mouseenter", () => {
    // @ts-expect-error style exists
    if (expandMenu.style.opacity === "1") {
      isMouseOnMenu = true;
    }
  });

  expandMenu.addEventListener("mouseleave", (e: Event) => {
    // @ts-expect-error e.y exists
    if (e.y > 70) {
      isMouseOnMenu = false;
      forceInitialState();
    }
  });

  document
    .querySelector(".nav__links")!
    .addEventListener("mouseleave", handleMouseLeave);

  return {
    reset: forceInitialState,
  };
};

export const defineAppMenu = ({
  prepend,
  append,
}: Partial<{
  prepend: MenuItem[];
  append: MenuItem[];
}> = {}) => {
  const { links, social } = useRuntimeConfig().public;

  return defineMenuItems([
    ...(prepend || []),
    {
      text: "For Business",
      component: {
        id: "platform",
        component: markRaw(PlatformMenu),
      },
      items: [
        { text: "E-commerce", to: { name: "sell-commerce" } },
        { text: "Schedule Appointments", to: { name: "sell-appointments" } },
        { text: "Organise Events", to: { name: "sell-events" } },
        { text: "Contact business", to: { name: "sell-contact" } },
      ],
    },
    {
      text: "Resources",
      component: {
        id: "resource",
        component: markRaw(ResourceMenu),
      },
      items: [
        { text: "FAQs", to: { name: "help-faqs" } },
        { text: "Pricing", to: { name: "sell-pricing" } },
        { text: "Admin Dashboard", href: links.dashboard, target: "_blank" },
        { text: "Instagram", href: social.instagram, target: "_blank" },
        { text: "Facebook", href: social.facebook, target: "_blank" },
        { text: "LinkedIn", href: social.linkedin, target: "_blank" },
      ],
    },
    // { text: "About Us", to: { name: "about" } },
    { text: "Contact", to: { name: "contact" } },
    ...(append || []),
  ]);
};
