<template>
  <div
    class="w-[25px] h-[20px] max-w-[25px] max-h-[20px] relative cursor-pointer pt-0.5"
    @click="toggle"
  >
    <div class="nav-icon" :class="{ open }">
      <span />
      <span />
      <span />
      <span />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: { type: Boolean, default: false },
  },
  emits: ["update:modelValue"],
  computed: {
    open: {
      set(value: boolean) {
        this.$emit("update:modelValue", value);
      },
      get() {
        return this.modelValue;
      },
    },
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
  },
});
</script>

<style lang="scss" scoped>
.nav-icon {
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: var(--icon-btn-color, white);
    border-radius: 1.5px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  span:nth-child(1) {
    top: 0px;
  }

  span:nth-child(2),
  span:nth-child(3) {
    top: 7px;
  }

  span:nth-child(4) {
    top: 14px;
  }

  &.open {
    span:nth-child(1) {
      top: 7px;
      width: 0%;
      left: 50%;
    }
    span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    span:nth-child(4) {
      top: 7px;
      width: 0%;
      left: 50%;
    }
  }
}
</style>
