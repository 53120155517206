<template>
  <header
    ref="element"
    class="header items-center"
    :class="{ scrolled, dark, 'disable-stick': disableStick, open }"
  >
    <v-container class="h-full py-0!">
      <div class="h-full flex items-center justify-between gap-2">
        <div class="nav__links flex items-center gap-4">
          <RouterLink
            to="/"
            class="group h-full flex items-center decoration-none gap-2 text-inherit mr-10 md:mr-20"
          >
            <Logo size="40" lucid />
          </RouterLink>

          <span
            v-for="(item, i) in menu"
            :key="i"
            class="nav--link hidden md:inline-block"
            :data-expand="item.component?.id"
          >
            <v-btn
              :to="item.to"
              :href="item.href"
              :target="item.target"
              variant="text"
              size="small"
              class="leading-none"
            >
              <span class="inline-flex gap-sm justify-between items-center">
                <span>{{ item.text }}</span>
                <Icon v-if="item.component" name="ep:arrow-down-bold" />
              </span>
            </v-btn>
          </span>
        </div>
        <v-spacer />
        <slot name="append" />
        <MobileHeader v-model="open" class="md:hidden" :menu="menu" />
      </div>

      <div class="tip" />
      <section class="header__expandMenu shadow-2xl border border-1">
        <div class="menu__container">
          <div v-for="cmp in menuComponents" :id="cmp.id" :key="cmp.id">
            <component :is="cmp.component" />
          </div>
        </div>
      </section>
    </v-container>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MobileHeader from "./MobileHeader.vue";
import { initDesktopMenus, type MenuItem } from "./lib";

export default defineComponent({
  components: { MobileHeader },
  props: {
    menu: { type: Array as PropType<MenuItem[]>, required: true },
  },
  setup(props) {
    const route = useRoute();
    const { links } = useRuntimeConfig().public;
    const open = ref(false);

    const menuComponents = props.menu
      .map((m) => m.component)
      .filter((cmp) => !!cmp);

    const dark = computed(() => !!route.meta.headerDark);

    const disableStick = computed(() => !!route.meta.disableHeaderStick);

    return {
      links,
      menuComponents,
      dark,
      disableStick,
      open,
    };
  },
  data: () => ({
    scrolled: false,
  }),
  mounted() {
    window.addEventListener("scroll", this.onScroll);

    // @ts-expect-error element is known
    const { reset } = initDesktopMenus(this.$refs.element);
    onRouteChange(reset);
  },
  methods: {
    onScroll() {
      this.scrolled = window.scrollY > 3;
    },
  },
});
</script>

<style lang="scss">
@use "/assets/styles/breakpoints";

$dark-color: rgba(0, 0, 0, 0.821);

.header {
  --expand-menu-width: 200px;

  height: 68px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;

  &.open {
    z-index: 1000;
  }

  &.disable-stick {
    position: absolute;
  }

  &:not(.dark) {
    color: white;
  }

  &.dark {
    --icon-btn-color: #{$dark-color};
  }

  &.scrolled:not(.disable-stick) {
    --icon-btn-color: white;
  }

  &.dark:not(.scrolled):not(.disable-stick) {
    --app-header-bg: #{$dark-color};
  }

  &.scrolled:not(.disable-stick) {
    color: white;
    background-color: $dark-color;
    transition: background-color 0.2s linear;
    backdrop-filter: blur(5px);
  }

  @include breakpoints.up(md) {
    --expand-menu-width: 100px;
  }

  @include breakpoints.up(xl) {
    --expand-menu-width: 500px;
  }
}

.tip {
  width: 1.25rem;
  height: 1.25rem;
  background-color: #ffffffba;
  position: absolute;
  top: 4.15rem;
  left: 0;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  transition: transform 250ms linear;
  opacity: 0;
}

.header__expandMenu {
  position: absolute;
  top: 4.5rem;
  left: calc(50% - var(--expand-menu-width));
  // left: 50%;
  width: 95%;
  height: 90%;
  transform-origin: 0%;
  transform: translateX(-50%) rotate3d(1, 0, 0, -15deg);
  background-color: white;
  border-radius: 0.5rem;
  perspective: 100px;
  transition:
    width 250ms ease,
    height 250ms ease,
    opacity 150ms ease,
    transform 200ms ease-in;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}

.menu__container {
  width: 100%;
  height: 100%;
}

.menu__container > * {
  position: absolute;
  top: 0;
  left: 50%;
  overflow: hidden;
  transform: translateX(-35%);
  transition:
    transform 250ms linear,
    opacity 250ms ease;
  opacity: 0;
}

.active {
  z-index: 1;
  opacity: 1;
  transform: translateX(-50%);
}

.prev {
  transform: translateX(-65%);
}

.expand {
  opacity: 1;
  pointer-events: all;
  transform: translateX(-50%) rotateX(0);
}

.hover {
  opacity: 0.5;
}

.new--expand {
  transition:
    opacity 150ms ease,
    transform 150ms ease-in;
}

.first {
  transition: opacity 400ms ease-in;
}
</style>
