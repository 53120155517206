<template>
  <div class="text-black flex divide-x">
    <div class="bg-slate-100 pa-5">
      <div class="font-semibold text-4 mb-4">Got questions?</div>
      <div class="mt-4 flex flex-col gap-2">
        <VArrowBtn :to="{ name: 'about' }" class="fit-content" size="small">
          Learn about us
        </VArrowBtn>
        <VArrowBtn
          :to="{ name: 'contact' }"
          variant="text"
          class="fit-content"
          size="small"
        >
          Contact us
        </VArrowBtn>
      </div>
    </div>
    <div class="w-400px">
      <div class="py-5">
        <v-list class="py-0!">
          <v-list-item
            v-for="(link, i) in links"
            :key="i"
            :href="link.href"
            :to="link.to"
            :title="link.text"
            :target="link.target"
            density="compact"
            class="py-0! px-5!"
          >
            <template #prepend>
              <Icon
                v-if="link.icon"
                :name="link.icon"
                size="20"
                class="text-gray-600 mr-2"
              />
            </template>
          </v-list-item>
        </v-list>
      </div>

      <div class="px-5">
        <v-divider />
      </div>

      <div class="py-5">
        <div class="mx-5 text-2.5 uppercase">Socials</div>
        <v-list class="mt-2 py-0!">
          <v-list-item
            v-for="(link, i) in socials"
            :key="i"
            :title="link.text"
            :href="link.href"
            target="_blank"
            density="compact"
            class="py-0! px-5!"
          >
            <template #prepend>
              <Icon
                v-if="link.icon"
                :name="link.icon"
                size="20"
                class="text-gray-600 mr-2"
              />
            </template>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { defineMenuItems } from "../lib";

export default defineComponent({
  setup() {
    const config = useRuntimeConfig().public;

    const links = defineMenuItems([
      {
        text: "Admin Dashboard",
        icon: "gala:editor",
        href: config.links.dashboard,
        target: "_blank",
      },
      {
        text: "FAQs",
        icon: "heroicons:chat-bubble-left-right",
        to: { name: "help-faqs" },
      },
      {
        text: "Pricing",
        icon: "heroicons:square-2-stack",
        to: { name: "sell-pricing" },
      },
    ]);

    const socials = defineMenuItems([
      {
        text: "Instagram",
        icon: "entypo-social:instagram",
        href: config.social.instagram,
      },
      {
        text: "Facebook",
        icon: "entypo-social:facebook",
        href: config.social.facebook,
      },
      {
        text: "LinkedIn",
        icon: "entypo-social:linkedin",
        href: config.social.linkedin,
      },
    ]);

    return {
      links,
      socials,
    };
  },
});
</script>
