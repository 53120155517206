<template>
  <AppDialogScreen v-model="isOpen">
    <template #action>
      <v-text-field
        :placeholder="defaultPlaceholder"
        width="240"
        class="lt-md:hidden! flat-text-field rounded-pill overflow-hidden"
        variant="filled"
        density="compact"
        hide-details
        autofocus
        readonly
        @click="open"
      >
        <template #prepend-inner>
          <Icon name="heroicons:magnifying-glass" size="22" />
        </template>
      </v-text-field>
      <v-btn
        class="md:hidden!"
        variant="text"
        density="comfortable"
        icon="search"
        @click="open"
      >
        <Icon name="heroicons:magnifying-glass" size="22" />
      </v-btn>
    </template>

    <template #content>
      <div class="app-search-input">
        <div class="rounded-pill overflow-hidden ma-4">
          <v-text-field
            v-model="query"
            :placeholder="placeholder"
            class="flat-text-field"
            variant="filled"
            density="compact"
            prepend-inner-icon="search"
            hide-details
            autofocus
          />
        </div>
        <v-tabs v-model="preset" density="compact" mandatory>
          <v-tab
            v-for="preset in categories"
            :key="preset.value"
            :value="preset.value"
            :ripple="false"
          >
            {{ preset.text }}
          </v-tab>
        </v-tabs>
        <v-divider />
      </div>

      <SearchContent
        :query="query"
        :hits="hits"
        :preset="preset"
        :recent-searches="history"
        :loading="loading"
        :search="search"
      />
    </template>
  </AppDialogScreen>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getPlaceholder, searchCategories } from "./lib";
import mousetrap from "mousetrap";
import { useDesktopSearchStore } from "@/stores/desktop-search";
import { storeToRefs } from "pinia";
import AppDialogScreen from "./AppDialogScreen.vue";
import SearchContent from "./AppSearchContent.vue";

export default defineComponent({
  name: "AppSearch",
  components: { AppDialogScreen, SearchContent },
  setup() {
    const input = ref<HTMLElement>();
    const isOpening = ref(false);
    const isOpen = ref(false);
    const port = ref();
    const shortcut = "/";

    const store = useDesktopSearchStore();
    const search = useSearch();
    const { visible: isFocused } = storeToRefs(store);

    const open = () => {
      isOpening.value = true;
      isOpen.value = true;
      input.value?.focus();
      isFocused.value = true;
      setTimeout(() => (isOpening.value = false), 1000);
    };

    const close = () => {
      isOpen.value = false;
      if (!isOpening.value) {
        isFocused.value = false;
      }
    };

    const register = () => {
      mousetrap.bind(shortcut, ($event: Event) => {
        $event.preventDefault();
        open();
      });
    };

    const defaultPlaceholder = `Hit "${shortcut}" to start searching`;

    const placeholder = computed(() =>
      isFocused.value ? getPlaceholder(search.preset.value) : defaultPlaceholder
    );

    onMounted(register);

    return {
      ...search,
      input,
      placeholder,
      defaultPlaceholder,
      isFocused,
      port,
      isOpen,
      open,
      close,
    };
  },
  data() {
    return {
      categories: searchCategories,
    };
  },
});
</script>

<style lang="scss">
.app-search-input {
  .v-btn__content {
    text-transform: none !important;
  }
  .v-tab__slider {
    border-radius: 3px 3px 0 0 !important;
  }
}
</style>
