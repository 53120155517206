<template>
  <div class="mobile-header" :class="{ open }">
    <div class="content">
      <div class="content-wrapper px-8">
        <div
          v-for="(item, i) in menu"
          :key="i"
          class="content-item text-6 mb-4"
        >
          <NavItem :item="item" />
        </div>
      </div>
    </div>
    <IconBtn v-model="open" />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import IconBtn from "./IconBtn.vue";
import NavItem from "./NavItem.vue";
import type { MenuItem } from "./lib";

export default defineComponent({
  components: { IconBtn, NavItem },
  props: {
    menu: { type: Array as PropType<MenuItem[]>, required: true },
  },
  setup(_, ctx) {
    const open = ref(false);

    // disable body scroll when open
    const toggleBodyScroll = (disable: boolean) => {
      if (disable) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    };

    watch(open, (newValue) => {
      ctx.emit("update:modelValue", newValue);
      toggleBodyScroll(newValue);
    });

    onUnmounted(() => {
      toggleBodyScroll(false);
    });

    onRouteChange(() => {
      open.value = false;
    });

    return {
      open,
    };
  },
});
</script>

<style lang="scss" scoped>
.mobile-header {
  position: relative;
  padding-right: 5px;

  .content {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #000;
    color: white;
    backface-visibility: hidden;
    pointer-events: none;
    visibility: hidden;
    transition: visibility 0.6s step-end;
    animation: slideOut 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }

  &.open {
    --app-header-bg: white;
    --icon-btn-color: white;
  }

  &.open .content {
    pointer-events: auto;
    visibility: visible;
    transition: visibility 0.6s step-start;
    animation: slideIn 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }

  .content-wrapper {
    overflow-y: scroll;
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-top: 85px;
    // pointer-events: auto;
    // visibility: visible;
  }

  .content-item {
    opacity: 0;
    transform: translateY(-2.5vw);
    transition:
      opacity 0.5s step-end,
      transform 0.5s step-end,
      -webkit-transform 0.5s step-end;
    transition-delay: 0s;

    // general
    display: block;
    // padding-left: 16px;
    // padding-right: 16px;
    // padding-bottom: 20px;
    font-family: "Clarkson", Helvetica, sans-serif;
    // font-style: normal;
    // font-weight: 500;
    // line-height: 1em;
    letter-spacing: 0;
    text-rendering: geometricPrecision;
    text-transform: none;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
  }

  &.open .content-item {
    opacity: 1;
    transform: translateY(0);
    transition:
      opacity 0.8s cubic-bezier(0.165, 0.84, 0.44, 1),
      transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1),
      -webkit-transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  @for $i from 0 through 9 {
    &.open .content-item:nth-child(#{$i}) {
      transition-delay: calc(0.32s + 0.08s * $i);
    }
  }
}

@keyframes slideOut {
  0% {
    clip-path: polygon(0 0, 101% 0, 101% 101%, 0 101%);
  }
  100% {
    clip-path: polygon(105% 0, 100% 0, 101% 100%, 100% 100%);
  }
}

@keyframes slideIn {
  0% {
    clip-path: polygon(101% 0, 100% 0, 101% 100%, 116% 100%);
  }
  100% {
    clip-path: polygon(0 0, 101% 0, 101% 101%, 0 101%);
  }
}
</style>
