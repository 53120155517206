<template>
  <div ref="root" class="app-dialog-screen">
    <slot name="action" />
    <v-overlay
      v-model="isOpen"
      class="app-dialog-overlay"
      :scrim="false"
      width="100%"
      persistent
    >
      <div class="relative">
        <div class="app-dialog-overlay__scrim" />
        <v-card
          ref="contentRef"
          v-click-outside="close"
          class="app-dialog-screen-content"
          variant="flat"
          rounded="0"
        >
          <v-container>
            <slot name="content" />
          </v-container>
        </v-card>
        <div
          class="md:hidden absolute top-6 right-5"
          style="--icon-btn-color: rgba(0, 0, 0, 0.821)"
        >
          <IconBtn v-model="isOpen" />
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useVModel } from "@vueuse/core";
import IconBtn from "@/components/layout/headers/IconBtn.vue";

export default defineComponent({
  name: "AppDialogScreen",
  components: { IconBtn },
  props: {
    modelValue: { type: Boolean, default: false },
  },
  setup(props) {
    const isOpen = useVModel(props, "modelValue");
    const root = ref<HTMLElement>();
    const contentRef = ref<any>();

    const close = () => {
      isOpen.value = false;
    };

    const handleScroll = (event: Event) => {
      // TODO: fix. This probs should not apply on mobile
      if (isOpen.value && contentRef.value) {
        const target = event.target as HTMLElement;
        if (!contentRef.value.$el.contains(target)) {
          close();
        }
      }
    };

    onMounted(() => {
      document.addEventListener("wheel", handleScroll);
    });

    onUnmounted(() => {
      document.removeEventListener("wheel", handleScroll);
    });

    return {
      isOpen,
      close,
      root,
      contentRef,
    };
  },
});
</script>

<style lang="scss">
@use "/assets/styles/breakpoints";

.app-dialog-overlay__scrim {
  position: absolute;
  height: 100vh;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: white;
  @include breakpoints.up(md) {
    background: rgba(255, 255, 255, 0.2) !important;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
}

.app-dialog-screen-content {
  padding-top: 80px;
  // height: 400px;
  position: relative;
  overflow: auto;
  @include breakpoints.up(md) {
    padding-top: 5vh;
  }
}
</style>
