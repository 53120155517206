<template>
  <v-list-item :title="hit.name" :to="url">
    <template #prepend>
      <ImageField v-if="hit.image" :src="hit.image" size="40" rounded />
      <v-avatar v-else color="grey-lighten-1" size="40">
        <v-icon color="white">storefront</v-icon>
      </v-avatar>
    </template>
    <template #subtitle>
      <div class="text-caption">
        <span>{{ hit.type }}</span>
        <template v-if="hit.nationality">
          <span class="mx-1">•</span>{{ hit.nationality }}
        </template>
      </div>
    </template>
  </v-list-item>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { BusinessProfileResult } from "../models";

export default defineComponent({
  name: "SearchContentBusinessProfileItem",
  props: {
    hit: { type: Object as PropType<BusinessProfileResult>, required: true },
  },
  computed: {
    url() {
      return {
        name: "shops-handle",
        params: { handle: this.hit.handle },
      };
    },
  },
  methods: {
    formatPrice,
  },
});
</script>
