<template>
  <div class="text-inherit">
    <span class="text-white flex justify-between items-center" @click="toggle">
      <RouteLink v-bind="item" />
      <span v-if="item.items" class="relative">
        <MenuIcon size="15" :active="reveal" />
      </span>
    </span>
    <div class="content" :class="{ open: reveal }">
      <ul class="list-none mt-2">
        <li v-for="(link, i) in item.items" :key="i" class="text-4.5 mb-1">
          <RouteLink v-bind="link" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { MenuItem } from "./lib";
import MenuIcon from "@/components/elements/ArrowChevronIcon.vue";

export default defineComponent({
  components: { MenuIcon },
  props: {
    item: { type: Object as PropType<MenuItem>, required: true },
  },
  data: () => ({
    reveal: false,
  }),
  methods: {
    toggle() {
      this.reveal = !this.reveal;
    },
  },
});
</script>

<style lang="scss" scoped>
.content {
  transition-duration: 0.5s, 0.5s, 0.5s, 0.5s, 0.5s;
  opacity: 0;
  -webkit-transform: translateY(3vw);
  transform: translateY(3vw);
  overflow: hidden;
  visibility: hidden;

  webkit-transition:
    height cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity cubic-bezier(0.165, 0.84, 0.44, 1),
    visibility step-end,
    -webkit-transform cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:
    height cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity cubic-bezier(0.165, 0.84, 0.44, 1),
    visibility step-end,
    -webkit-transform cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:
    height cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity cubic-bezier(0.165, 0.84, 0.44, 1),
    transform cubic-bezier(0.165, 0.84, 0.44, 1),
    visibility step-end;

  transition:
    height cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity cubic-bezier(0.165, 0.84, 0.44, 1),
    transform cubic-bezier(0.165, 0.84, 0.44, 1),
    visibility step-end,
    -webkit-transform cubic-bezier(0.165, 0.84, 0.44, 1);
  height: 0px;

  &.open {
    transition-duration: 0.8s, 0.65s, 0.65s, 0.65s, 0.65s;
    height: auto;

    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
    -webkit-transition-timing-function:
      cubic-bezier(0.165, 0.84, 0.44, 1), cubic-bezier(0.165, 0.84, 0.44, 1),
      cubic-bezier(0.165, 0.84, 0.44, 1), step-start;
    transition-timing-function:
      cubic-bezier(0.165, 0.84, 0.44, 1), cubic-bezier(0.165, 0.84, 0.44, 1),
      cubic-bezier(0.165, 0.84, 0.44, 1), step-start;
  }
}
</style>
